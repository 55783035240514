import styles from "./Home.module.css";
import image from "../../image/Logotipo-Original.webp";
import { Link } from "react-scroll";
import React, { useEffect, useState } from "react";

export default function Home() {
  const [estaRodando, setEstaRolando] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 50) {
        setEstaRolando(true);
      } else {
        setEstaRolando(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`${styles.header} ${estaRodando ? styles.Rolando : ""}`}>
      <div className={styles.menu}>
        <div className={styles.divHamburguer}>
          <button
            className={styles.hamburgerButton}
            onClick={() => setIsHamburgerMenuOpen(!isHamburgerMenuOpen)}
          >
            ☰
          </button>
          {isHamburgerMenuOpen && (
            <nav
              className={`${styles.mobileMenu} ${
                isHamburgerMenuOpen ? styles.open : ""
              }`}
            >
              <li className={styles.liMenu}>
                <Link
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={1000}
                  className={styles.botao}
                  onClick={() => setIsHamburgerMenuOpen(false)}
                >
                  HOME
                </Link>
              </li>
              <li className={styles.liMenu}>
                <Link
                  to="servicos"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={1500}
                  className={styles.botao}
                  onClick={() => setIsHamburgerMenuOpen(false)}
                >
                  SERVIÇOS
                </Link>
              </li>
              <li className={styles.liMenu}>
                <a
                  href="https://api.whatsapp.com/send?phone=5535987190560&text=Ol%C3%A1,%20gostaria%20de%20fazer%20um%20or%C3%A7amento!"
                  className={styles.botao}
                  onClick={() => setIsHamburgerMenuOpen(false)}
                >
                  CONTATO
                </a>
              </li>
            </nav>
          )}
          </div>
          <div>

          <a
            href="https://api.whatsapp.com/send?phone=5535987190560&text=Ol%C3%A1,%20gostaria%20de%20fazer%20um%20or%C3%A7amento!"
            className={styles.btn}
          >
            SOLICITAR ORÇAMENTO
          </a>
        </div>
      </div>
      <div className={styles.divHome}>
        <div className={styles.divImage}>
          <img src={image} className={styles.img} alt="Logotipo" />
        </div>
        <nav className={styles.nav}>
          <li className={styles.li}>
            <Link
              to="home"
              spy={true}
              smooth={true}
              offset={50}
              duration={1000}
              className={styles.botao}
            >
              HOME
            </Link>
          </li>
          <li className={styles.li}>
            <Link
              to="servicos"
              spy={true}
              smooth={true}
              offset={50}
              duration={1500}
              className={styles.botao}
            >
              SERVIÇOS
            </Link>
          </li>
          <li className={styles.li}>
            <a
              href="https://api.whatsapp.com/send?phone=5535987190560&text=Ol%C3%A1,%20gostaria%20de%20fazer%20um%20or%C3%A7amento!"
              className={styles.botao}
            >
              CONTATO
            </a>
          </li>
        </nav>
        <div className={styles.caixaBtn}>
          <a
            href="https://api.whatsapp.com/send?phone=5535987190560&text=Ol%C3%A1,%20gostaria%20de%20fazer%20um%20or%C3%A7amento!"
            className={styles.btn}
          >
            SOLICITAR ORÇAMENTO
          </a>
        </div>
      </div>
    </header>
  );
}
