import css from "./Servicos.module.css";

const dados = [
  {
    titulo: "Esquadrias em alumínio",
    image: "/image/Esquadrias.png",
    texto: "Todos os tipos de esquadrias e variedade de cores",
  },
  {
    titulo: "Fachadas",
    image: "/image/fachada.png",
    texto: "Melhores acabamentos e completamente adaptável a sua necessidade",
  },
  {
    titulo: "Espelhos",
    image: "/image/espelho.png",
    texto: "Todos os tipos de acabamentos com espelhos",
  },
  {
    titulo: "Vidro temperado",
    image: "/image/blindex.png",
    texto: "Melhores materiais e ótimo acabamento",
  },
  {
    titulo: "Box",
    image: "/image/box.png",
    texto: "Todas as possibilidade de configurações e variedade de vidros",
  },
  {
    titulo: "Persiana",
    image: "/image/persiana.png",
    texto: "Produzimos as janelas com as dimensões exatas da sua necessidade",
  },
];
const imagens = [
  { img: "./image/servico1.webp", titulo: "imagem 1", id: "1" },
  { img: "./image/servico2.webp", titulo: "imagem 2", id: "2" },
  { img: "./image/servico3.webp", titulo: "imagem 3", id: "3" },
  { img: "./image/servico4.webp", titulo: "imagem 5", id: "5" },
];

export default function Servicos() {
  return (
    <section id="servicos" className={css.section}>
      {/* <div className={css.div}>
        {dados.map((dado) => (
          <li className={css.li}>
            <h4 className={css.h4}>{dado.titulo}</h4>
            <img className={css.img} src={dado.image} alt={dado.titulo}></img>
            <p className={css.p}>{dado.texto}</p>
          </li>
        ))}
      </div> */}
      <div className={css.div}>
        {imagens.map((dado) => (
          <li className={css.li}>
            <img className={css.img} src={dado.img} alt={dado.titulo}></img>
          </li>
        ))}
      </div>
      <div className={css.boxOrcamento}>
        <a
          href="https://api.whatsapp.com/send?phone=5535987190560&text=Ol%C3%A1,%20gostaria%20de%20fazer%20um%20or%C3%A7amento!"
          className={css.orcamento}
        >
          Solicitar orçamento
        </a>
      </div>
    </section>
  );
}
