import css from "./BannerMelhorEmpresa.module.css";

export default function BannerMelhorEmpresa() {
  return (
    <section className={css.container}>
      <div className={css.divBege}></div>
      <div className={css.divPreta}>
        <div className={css.texto}>
          <h3 className={css.h3}>Melhor empresa para te atender</h3>
          <p className={css.p}>
            Temos uma equipe especializada preparada para te prestar o melhor
            atendimento e encontrar a melhor solução para otimizar o seu projeto
            e ainda aliar os melhores preços ao melhores materiais. Veja nosso
            portfólio e confira como podemos tornar seu sonho em realidade.
          </p>
        </div>
      </div>
    </section>
  );
}
