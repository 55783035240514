import css from "./BannerDepoimentos.module.css"
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import declaracao from "./declaracao.json"

export default function BannerDepoimentos(){
    return(
        <section>
            <div className={css.textoUpper}>
            <Carousel
              autoPlay={true}
              interval={5000}
              infiniteLoop={true}
              showArrows={false}
              showThumbs={false}
              easing="ease"
              transitionTime={1000}
              showStatus={false}
            >
              {declaracao.map(depoimento => (               
              <div style={{ padding: "2rem 0" }} key={depoimento.id}>
                <p className={css.texto}>{`"${depoimento.texto}"`}</p>
                <p className={css.nome}>{depoimento.titulo}</p>
              </div>
              ))}
            </Carousel>
          </div>
        </section>
    )
}