import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import Home from './components/Home';
import { createGlobalStyle } from 'styled-components';
import BannerTopo from './components/BannerTopo';
import BannerApresentacao from './components/BannerApresentacao';
import BannerMelhorEmpresa from './components/BannerMelhorEmpresa';
import BannerCarrosel from './components/BannerCarrossel';
import BannerOrcamento from './components/BannerOrcamento';
import Servicos from './components/Servicos';
import BannerConfia from './components/BannerConfia';
import BannerDepoimentos from './components/BannerDepoimentos';
import Instagram from './components/Instagram';
import Footer from './components/Footer';


const GlobalStyled = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, 'Montserrat', 'Poppins';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  li {
    list-style: none;
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyled />
    <Home />
    <BannerTopo/>
    <BannerApresentacao/>
    <BannerMelhorEmpresa/>
    <BannerCarrosel/>
    <BannerOrcamento/>
    <Servicos/>
    <BannerConfia/>
    <BannerDepoimentos/>
    <Instagram/>
    <Footer/>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
