import styles from "./BannerTopo.module.css";
import image from "../../image/fundobannter.webp";
import logo from "../../image/Logotipo-Original.webp";

export default function BannerTopo() {
  return (
    <div className={styles.containerBanner} id="home">
      <img src={image} className={styles.img} />     
        <img src={logo} className={styles.logo}></img>    
      <div className={styles.apresentacao}>
        <div className={styles.Titulo}>
          <h1 className={styles.h1Top}>
          Transforme seu espaço com elegância e qualidade
          </h1>
        </div>
        <p className={styles.texto}>
        Soluções personalizadas e duráveis pararesidências, comércios e empreendimentosna região, com atendimento especializado ecompromisso com a satisfação do cliente
        </p>
        <div className={styles.boxBtn}>
          <a
            href="https://api.whatsapp.com/send?phone=5535987190560&text=Ol%C3%A1,%20gostaria%20de%20fazer%20um%20or%C3%A7amento!"
            className={styles.btn}
          >
            Fale com um consultor!
          </a>
        </div>
      </div>
    </div>
  );
}
