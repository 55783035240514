import css from "./Footer.module.css";
import image from "../../image/logo.png";
import { Link } from "react-scroll";

export default function Footer() {
  return (
    <footer className={css.footer}>
      <div className={css.container}>
        <div className={css.logo}>
          <div>
            <img src={image} alt="logo" className={css.img}></img>
          </div>
          <div className={css.nav}>
            <Link
              to="home"
              spy={true}
              smooth={true}
              offset={50}
              duration={1000}
              className={css.li}
            >
              HOME
            </Link>
            <Link
              to="servicos"
              spy={true}
              smooth={true}
              offset={50}
              duration={1000}
              className={css.li}
            >
              SERVIÇOS
            </Link>
            <li
              href="https://api.whatsapp.com/send?phone=5535987190560&text=Ol%C3%A1,%20gostaria%20de%20fazer%20um%20or%C3%A7amento!"
              className={css.li}
            >
              CONTATO
            </li>
          </div>
        </div>
        <div className={css.contato}>
          <li className={css.telefone}>35 98719-0560</li>
          <p className={css.endereco}>
            Avenida Moisés Lopes, 195 Árvore Grande, Pouso Alegre - MG
          </p>
          <p className={css.email}>orcamentos@aluminobre.com.br</p>
        </div>
      </div>
      <div className={css.assinatura}>
        <p className={css.assinaturaTexto}>
          ©2023 Todos os direitos reservado Aluminobre | Desenvolvido por
        </p>
        <a href="http://silasmariosa.com.br/" className={css.assinaturaAutor}>
          Mariosa Tech
        </a>
      </div>
      <div className={css.divZap}>
        <a className={css.btnZap} href="https://api.whatsapp.com/send?phone=5535987190560&text=Ol%C3%A1,%20gostaria%20de%20fazer%20um%20or%C3%A7amento!">
          <img className={css.iconZap} src="../../image/zap.svg" alt="icon zap"></img>
        </a>
      </div>
    </footer>
  );
}
