import css from "./Instagram.module.css";

export default function Instagram() {
  return (
    <section className={css.section}>
      <div className={css.div}>
        <a href="https://www.instagram.com/aluminobre.pa/" className={css.btn}>@aluminobre.pa</a>
      </div>
    </section>
  );
}
