import css from "./BannerOrcamento.module.css";

export default function BannerOrcamento() {
  return (
    <section className={css.section}>
      <div className={css.divPai}>
        <div className={css.divPreto}></div>
        <div className={css.divDourado}></div>
        <div className={css.boxText}>
          <h3 className={css.h3}>Faça seu orçamento agora!</h3>
          <a href="https://api.whatsapp.com/send?phone=5535987190560&text=Ol%C3%A1,%20gostaria%20de%20fazer%20um%20or%C3%A7amento!"  className={css.a}>Quero saber mais!</a>
        </div>
      </div>
    </section>
  );
}
