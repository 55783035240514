import styles from "./BannerApresentacao.module.css";
import video from "../../image/videoObra.mp4";
import imagem from "../../image/WhatsApp Image 2023-10-24 at 17.21.54.jpeg";

export default function BannerApresentacao() {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.divTexto}>
          <h2 className={styles.subtitulo}>SOBRE NÓS:</h2>
          <p className={styles.texto1}>
            A Aluminobre é uma empresa especializada em Esquadrias de Alumínio,
            Vidro temperado, Box e espelhos.
          </p>
          <p className={styles.texto}>
            Com anos de experiência e compromisso com a qualidade e a satisfação
            do cliente, nos tornamos referência no setor, fornecendo soluções
            personalizadas e duráveis para residências, comércios e
            empreendimentos.
          </p>
          <div className={styles.orcamentoBtn}>
            <a
              href="https://api.whatsapp.com/send?phone=5535987190560&text=Ol%C3%A1,%20gostaria%20de%20fazer%20um%20or%C3%A7amento!"
              className={styles.orcamento}
            >
              Solicitar orçamento
            </a>
          </div>
        </div>
        <div className={styles.divVideo}>
          <video autoPlay loop muted className={styles.Video}>
            <source src={video} type="video/mp4" className={styles.Source} />
          </video>
        </div>
      </div>
      <div className={styles.boxAtendemos}>
        <img
          src={imagem}
          alt="foto frente loja"
          className={styles.imagem}
        ></img>
        <div className={styles.divAtendemos}>
          <h2 className={styles.h2atendimento}>
            ATENDEMOS <strong style={{ color: "#A9790F" }}>POUSO ALEGRE</strong>{" "}
            E TODA A REGIÃO: em um raio de 200km
          </h2>
        </div>
      </div>
    </section>
  );
}
