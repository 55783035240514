import React from 'react';
import "swiper/swiper-bundle.css";
import css from "./BannerCarrosel.module.css";
import images from './imagens.json';

import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from 'swiper/modules';

export default function BannerCarrosel() {
  return (
    <section className={css.carousel}>
      <h2 className={css.h2}><strong style={{color:"#a9790f"}}>+1000 obras entregues em</strong> Pouso Alegre e por todo Sul de Minas.</h2>
      <Swiper
      autoplay={true}
        slidesPerView={1}
        spaceBetween={10}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{          
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {images.map((data) => (
          <SwiperSlide key={data.id}>
            <img src={data.img} alt={data.titulo} className={css.image} />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}
